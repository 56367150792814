import React, { PropsWithChildren, ReactElement } from "react";
import Card from "./Card"
import { Link } from "gatsby";
import styles from "./ClubCards.module.scss";

interface ClubCardsProps
{
    data:any
}

const ClubCards = (props: PropsWithChildren<ClubCardsProps>): ReactElement => {
    const { data } = props

    return (
    <div className={`${styles.cardsWrapper} ${data.length === 2 ? styles.centered : ""}`}>
        <h2 className={styles.sectionTitle}>
            Locations
        </h2>
        {
            data.map((location:any, i:number)=>(
               <Card {...{...location}} key={i} />
            ))
        }

    </div>
)};

export default ClubCards;
