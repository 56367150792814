import React, { PropsWithChildren, ReactElement } from "react";
import Card from "./Card"
import styles from "./ClubCardsHorizontal.module.scss";

interface ClubCardsProps
{
    data:any
}

const ClubCardsHorizontal = (props: PropsWithChildren<ClubCardsProps>): ReactElement => {
    const { data } = props

    return (
    <div className={styles.cardsWrapper}>
        <h2 className={styles.sectionTitle}>
            Address
        </h2>
        {
            data.map((location:any, i:number)=>(
               <Card {...{...location}} key={i} />
            ))
        }

    </div>
)};

export default ClubCardsHorizontal;
