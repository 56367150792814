import React, { PropsWithChildren, ReactElement } from "react";
import { downloadIcon } from "../../../shared/icons";
import styles from "./ScheduleDownload.module.scss";

interface ScheduleDownloadProps {
    dataRaw: any;
}

const ScheduleDownload = (
    props: PropsWithChildren<ScheduleDownloadProps>
): ReactElement => {
   
    const { dataRaw: { schedule_download_archives, schedule_download_title, schedule_download_subtitle, schedule_download_text } } = props

    return (
        <section
            className={styles.scheduleDownload}
        >
            <div className={`${styles.textContainer}`}>
                {schedule_download_title[0] && <h2>{schedule_download_title[0]?.text}</h2>}

                {schedule_download_subtitle[0] && <h3>{schedule_download_subtitle[0]?.text}</h3>}

                {schedule_download_text.length &&
                    schedule_download_text.map((x: any, i: number) => (
                        <p key={i} className={styles.bodyTextCentered} >{x.text}</p>
                    ))}

                <div className={styles.downloadLinksWrapper}>
                    {schedule_download_archives.length > 0 &&
                        schedule_download_archives.map(
                            (item: any, i: number) =>
                                item.schedule_downloadable_title && (
                                    <a
                                        key={i}
                                        href={item.schedule_download?.url}
                                        title="Download"
                                        target="_blank"
                                    >
                                        {item.schedule_downloadable_title[0]?.text}
                                        {downloadIcon}
                                    </a>
                                )
                        )}
                </div>
            </div>

        </section>
    );
};

export default ScheduleDownload;
