import { Link } from "gatsby";
import React, { ReactElement } from "react";
import { mobilePhoneIcon, mapIcon , arrows  } from "../../../../shared/icons";
import AmenitiesBox from "./AmenitiesBox"
import styles from "./Card.module.scss";

interface CardProps {
    amenities: Object[],
    dataRaw: any,
    uid:string
}
interface Address {
    text: string
}
const Card = (props: CardProps): ReactElement => {

    const { amenities, dataRaw, uid } = props
    const {
        page_title,
        location_img,
        club_address,
        phone,
        location_description
    } = dataRaw

    return (
        <div className={styles.cardWrapper}>
            <div className={styles.bodyData} >
            {page_title && <h3 className={styles.cardClubTitle}>{page_title[0]?.text} </h3>}

                <div className={styles.contactData}>
                    <div className={styles.direction} >
                        <div className={styles.data}>
                            {club_address && club_address.map((address: Address, i: number) => (
                                <p key={i}>{address.text}</p>
                            ))}
                        </div>

                    </div>

                    <div className={styles.phone} >
                        {phone &&
                            <a className={styles.phoneLink} href={`tel:${phone[0]?.text.replace(/[^0-9]/ig, "")}`} key={`phone-${uid}`}>{phone[0]?.text}</a>
                        }
                    </div>

                </div>

                <AmenitiesBox data={amenities} />

                <div className={styles.description}>

                    {location_description &&
                        location_description.map((paragraph: any, i:number) => (
                            <p key={i}>{paragraph.text}</p>
                        ))
                    }

                </div>

            <Link to={`locations/${uid}`} className={styles.link}>
                More about this location
                <span>{arrows.right}</span>
            </Link>           
            </div>

            <div className={styles.cardImageheader}>
                {location_img && <img src={location_img?.url} alt={location_img?.alt} className={styles.background} />}
            </div>
        
        </div>

    )
};

export default Card;
