import { Link } from "gatsby";
import React, { PropsWithChildren, ReactElement, useState, useEffect } from "react";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { WarningService } from "../../../services/WarningService";
import { closeBtn } from "../../../shared/icons";
import { IData } from "./Interfaces/IData";
import styles from "./TwoSidesImageBanner.module.scss";

interface TwoSidesImageBannerProps {
    data: IData;
}


const TwoSidesImageBanner = (
    props: PropsWithChildren<TwoSidesImageBannerProps>
): ReactElement => {
    const {
        left_banner_img,
        left_banner_title,
        left_banner_description_title,
        left_banner_description_text,
        left_banner_cta_label,
        left_banner_cta_link,
        right_banner_img,
        right_banner_title,
        right_banner_description_title,
        right_banner_description_text,
        right_banner_cta_label,
        right_banner_cta_link,
    } = props.data;

    const blocks = [
        {
            background: left_banner_img,
            wrapperTitle: left_banner_title,
            title: left_banner_description_title
                ? left_banner_description_title[0]?.text
                : " ",
            text: left_banner_description_text
                ? left_banner_description_text
                : "",
            link_label: left_banner_cta_label,
            link: left_banner_cta_link,
            className: styles.leftSide,
        },
        {
            background: right_banner_img,
            wrapperTitle: right_banner_title,
            title: right_banner_description_title
                ? right_banner_description_title[0]?.text
                : " ",
            text: right_banner_description_text
                ? right_banner_description_text
                : "",
            link_label: right_banner_cta_label,
            link: right_banner_cta_link,
            className: styles.rightSide,
        },
    ];

    const isDesktop = useWindowWidth(992);

    const [infoBlockData, setInfoBlockData] = useState(null as any);


    const setData = (block: any, index: number) => {
        setInfoBlockData({
            title: block.title,
            text: block.text,
            cta: {
                label: block.link_label[0]?.text,
                link: block.link.uid,
                type: block.link.type,
            },
            index,
        });
    };

    if (!left_banner_img || !right_banner_img) WarningService("Two sides image banner", "image background")
    if (!left_banner_title || !right_banner_title) WarningService("Two sides image banner", "banner title")

    const handlePointerLeave = () => {
        if (isDesktop) setInfoBlockData(null);
    }

    return (
        <div className={styles.twoSidesImageBanner}>
            {blocks.map((block, i) => (
                <div
                    className={`${infoBlockData && i == infoBlockData.index ? styles.basis : ""} ${block.className}`}
                    style={{ backgroundImage: `url(${block.background?.url})` }}
                    onPointerEnter={() => setData(block, i)}
                    onPointerDown={() => setData(block, i)}
                    onPointerLeave={() => handlePointerLeave()}
                    key={i}
                >
                    {block.wrapperTitle && <h2>{block.wrapperTitle[0].text}</h2>}
                    {infoBlockData && i === infoBlockData.index && (
                        <div className={styles.infoBlockData} >
                            <span onClick={() => setInfoBlockData(null)}>{closeBtn}</span>
                            {infoBlockData.title &&
                                infoBlockData ?
                                (
                                    <h2>{infoBlockData.title}</h2>
                                ) :
                                WarningService("Two sides image banner", "side title")
                            }
                            <div className={styles.infoBlockData_text}>
                                {infoBlockData.text && infoBlockData.text.length > 0 ?
                                    infoBlockData.text.map((data: any, i: number) => (
                                        <p key={i}>{data.text}</p>
                                    )) :
                                    WarningService("Two sides image banner", "side description")
                                }
                            </div>
                            {infoBlockData.cta.link && infoBlockData.cta.label ? <Link
                                className={styles.buttonLink}
                                to={`${infoBlockData.cta.type}/${infoBlockData.cta.link}`}
                            >
                                {infoBlockData.cta.label}
                            </Link> :
                                WarningService("Two sides image banner", "CTA (label or link)")
                            }
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default TwoSidesImageBanner;
