import React, { PropsWithChildren } from "react";
import styles from "./HeroMedia.module.scss";
import useWindowWidth, { heroDesktopMinWidth } from "../../../hooks/useWindowWidth";
import useIsBrowser from  "../../../hooks/useIsBrowser";

interface HeroMediaProps {
    data: any;
    height?:number
}

const HeroMedia = (props: PropsWithChildren<HeroMediaProps>): any => {
    const {
        hero_background,
        hero_title,
        hero_text_alignment,
        hero_overlay_color,
        hero_text_color,
        mobile_background,
        hero_alt_text,
        hero_alt_text_mobile
    } = props.data;

    const { key } = useIsBrowser();
    const isDesktop = useWindowWidth(heroDesktopMinWidth);

    const hasDesktopVideo = hero_background.kind === "document";
    const hasMobileVideo = mobile_background.kind === "document";

    const setTextAlignment = (): string => {
        switch (hero_text_alignment) {
            case "Center":
                return styles.alignedCenter;
            case "Right":
                return styles.alignedRight;
            default:
                return styles.alignedLeft;
        }
    };

    const heroAltText = (): string => 
    {
        let altText = "";
        if (isDesktop && hero_alt_text?.length > 0) {
            altText = hero_alt_text[0].text;
        } else if (!isDesktop && hero_alt_text_mobile?.length > 0) {
            altText = hero_alt_text_mobile[0].text;
        }
        return altText;
    }

    return (
        <section
            key={key}
            className={`${styles.heroMedia} 
                ${hasDesktopVideo ? styles.hasDesktopVideo : ""}
                ${hasMobileVideo ? styles.hasMobileVideo : ""}
            `}
            style={{
                ["--heroOverlayColor" as string]: hero_overlay_color,
                ["--heroTextColor" as string]: hero_text_color,
                ["--heroHeight" as string]: props.height ? `${props.height}vh`:"",
            }}
        >
            {hero_title && <h1 className={setTextAlignment()}>{hero_title[0]?.text}</h1>}
            <img src={isDesktop ? hero_background.url : mobile_background.url} alt={heroAltText()} />
            <video autoPlay muted loop src={isDesktop ? hero_background.url : mobile_background.url} />
        </section>
    );
};

export default HeroMedia;