import React, { PropsWithChildren, ReactElement } from "react";
import {personalIcon, pinIcon} from "../../../../../shared/icons"
import styles from "./AmenitiesBox.module.scss";

interface AmenitiesProps {
    data: object[]
}

const AmenitiesBox = (props: PropsWithChildren<AmenitiesProps>): ReactElement => {
    const { data } = props

    return (
        <div className={styles.amenitesWrapper}>
            <h3 className={styles.title}> AMENITIES</h3>
            
            <div className={styles.amenities}>

                {data.map((amenity: any, i:number) => (
                    <div className={styles.amenity} key={i}>                        
                        {amenity.dataRaw.title && <p> {amenity.dataRaw.title[0]?.text}</p>}
                    </div>
                ))}

            </div>
        </div>
    )
};

export default AmenitiesBox;
